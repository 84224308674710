'use client'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ReactElement, ReactNode, forwardRef, useState } from 'react'
import {
  ActionMeta,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue
} from 'react-select'
import AsyncMultipleSearchWithSelectOptionGroup from '~/components/Settings/Members/AsyncMultipleSearchWithSelectOptionGroup'
import {
  AsyncMultipleSearchWithSelect,
  AsyncMultipleSearchWithSelectProps
} from '~/core/ui/AsyncMultipleSearchWithSelect'
import {
  AsyncSingleSearchWithSelect,
  AsyncSingleSearchWithSelectProps
} from '~/core/ui/AsyncSingleSearchWithSelect'
import { ISelectOption } from '~/core/ui/Select'

interface DropdownOptionAsyncProps {
  defaultOpen?: boolean
  trigger?: ReactNode
  renderTrigger?: (args: {
    onOpenChange: (open: boolean) => void
    open: boolean
  }) => ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
  align?: 'start' | 'center' | 'end'
  sideOffset?: number
  className?: string
  configSelect: AsyncMultipleSearchWithSelectProps &
    AsyncSingleSearchWithSelectProps & {
      isMulti?: boolean
      isMultiGroup?: boolean
      customerDropdownHeader?: ReactElement
      mappingGroupData?: (
        data?: ISelectOption[]
      ) => OptionsOrGroups<ISelectOption, GroupBase<ISelectOption>>
    }
  portalContainer?: HTMLElement | null
  isDisabled?: boolean
  isCloseDropdownOnSelect?: boolean
  limit?: number
  loadAsyncWhenOpen?: boolean
}

const DropdownOptionAsync = forwardRef<
  React.ElementRef<typeof DropdownMenu.Content>,
  DropdownOptionAsyncProps
>(
  (
    {
      defaultOpen = false,
      trigger,
      renderTrigger,
      side = 'top',
      align = 'start',
      sideOffset = 5,
      className,
      portalContainer,
      isDisabled,
      configSelect: { value, onChange, destructive, ...otherConfigSelect },
      limit = 25,
      loadAsyncWhenOpen = true,
      isCloseDropdownOnSelect = false
    },
    ref
  ) => {
    const [open, onOpenChange] = useState(defaultOpen)
    const mergedOptions = {
      isDropdown: true,
      autoFocus: true,
      showDropdownIndicator: false,
      showClearIndicator: false,
      backspaceRemovesValue: false,
      controlShouldRenderValue: false,
      hideSelectedOptions: false,
      isClearable: false,
      menuIsOpen: true,
      tabSelectsValue: false,
      value,
      limit,
      loadAsyncWhenOpen,
      onChange: (
        newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
        actionMeta: ActionMeta<ISelectOption>
      ) => {
        if (actionMeta?.option?.disabled) {
          return
        }
        if (isCloseDropdownOnSelect) {
          onOpenChange(false)
        }
        onChange(newValue, actionMeta)
      },
      ...otherConfigSelect
    }

    return (
      <DropdownMenu.Root
        {...(renderTrigger ? { open, onOpenChange } : { open, onOpenChange })}
      >
        {trigger ? (
          <DropdownMenu.Trigger disabled={isDisabled} asChild>
            <div>{trigger}</div>
          </DropdownMenu.Trigger>
        ) : null}
        {renderTrigger && <>{renderTrigger({ onOpenChange, open })}</>}

        <DropdownMenu.Portal container={portalContainer}>
          <DropdownMenu.Content
            ref={ref}
            align={align}
            side={side}
            sideOffset={sideOffset}
            onEscapeKeyDown={(e) => onOpenChange(false)}
            className={`rounded bg-white shadow-ats dark:bg-gray-900 dark:shadow-dark-ats ${className}`}
          >
            {otherConfigSelect.customerDropdownHeader &&
              otherConfigSelect.customerDropdownHeader}
            {otherConfigSelect?.isMultiGroup ? (
              <AsyncMultipleSearchWithSelectOptionGroup
                showSelectOptionAll={false}            
                {...mergedOptions}
              />
            ) : otherConfigSelect?.isMulti ? (
              <AsyncMultipleSearchWithSelect {...mergedOptions} />
            ) : (
              <AsyncSingleSearchWithSelect {...mergedOptions} />
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    )
  }
)

DropdownOptionAsync.displayName = 'DropdownOptionAsync'

export { DropdownOptionAsync }
export type { DropdownOptionAsyncProps }
